<template>
  <div class="buyOrderInfo">
    <div v-if="httpShow">
      <div class="cell">
        <div class="top">
          <div @click="toBack" style="cursor: pointer">
            <i class="el-icon-arrow-left"></i>
            回上页
          </div>
          <div style="color: #ee4d2d">{{ state[orderData.state] }}</div>
        </div>
        <div class="seep" v-if="orderData.state != 6">
          <div class="box">
            <div style="display: flex; align-items: center">
              <div class="round round1">
                <i class="el-icon-document"></i>
              </div>
              <div class="line line2"></div>
            </div>
            <div
              style="display: flex; align-items: center"
              v-for="(item, i) in seepList"
              :key="i"
            >
              <div
                class="line"
                :class="item.show ? 'line2' : 'line1'"
                v-if="item.value != 1"
              ></div>
              <div class="round" :class="item.show ? 'round2' : 'round3'">
                <i :class="item.icon"></i>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <div class="text">
            <div>
              <div style="min-width: 160px">订单已成立</div>
              <div style="font-size: 12px; margin-top: 4px; color: #d2d2d0">
                {{ ms }}
              </div>
            </div>
            <div v-for="(item, i) in seepList" :key="i">
              <div style="min-width: 160px">{{ item.title }}</div>
              <!-- <div style="font-size: 12px; margin-top: 4px; color: #d2d2d0">
              {{ item.ms }}
            </div> -->
            </div>
          </div>
        </div>
        <div class="type" v-if="orderData.state == 1">
          <div>等待买家支付</div>
          <div>
            <el-button style="min-width: 200px" plain @click="handlePay"
              >确认付款</el-button
            >
          </div>
        </div>
        <div class="type" v-if="orderData.state == 2">
          <div>等待卖家出货中</div>
          <div>
            <el-button style="min-width: 200px" disabled plain type="info"
              >等待出货</el-button
            >
          </div>
        </div>
        <div class="type" v-if="orderData.state == 3">
          <div>等待卖家发货中</div>
          <div>
            <el-button style="min-width: 200px" disabled plain type="info"
              >等待发货</el-button
            >
          </div>
        </div>
        <div class="type" v-if="orderData.state == 4">
          <div>卖家已发货，等待收货中</div>
          <div>
            <el-button style="min-width: 200px" plain @click="handleConfirm"
              >确认收货</el-button
            >
          </div>
        </div>
        <div class="type" v-if="orderData.state == 5">
          <div v-if="orderData.evaluate_state == 0">订单已确认收货，待评价</div>
          <div v-else>订单已完成</div>
          <div v-if="orderData.evaluate_state == 0">
            <el-button
              @click="dialogVisible1 = true"
              style="min-width: 200px"
              plain
              >写评价</el-button
            >
          </div>
        </div>
        <div class="type2" v-if="orderData.state == 1">
          <div>
            <el-button
              @click="dialogVisible = true"
              style="min-width: 200px"
              plain
              >取消订单</el-button
            >
          </div>
        </div>
        <div
          class="type3"
          v-if="orderData.state == 5 && orderData.evaluate_state == 1&&orderData.comment.length"
        >
          <div style="display: flex; align-items: center">
            <span>我的评价:</span>
            <el-rate disabled v-model="orderData.comment[0].state"></el-rate>
          </div>
          <div
            v-if="orderData.comment[0].content.length < 50"
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ orderData.comment[0].content }}
          </div>
          <el-popover
            v-else
            placement="top"
            width="1000"
            trigger="hover"
            :content="orderData.comment[0].content"
          >
            <div
              slot="reference"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
              "
            >
              {{ orderData.comment[0].content }}
            </div>
          </el-popover>
        </div>
        <div class="address">
          <div style="padding: 40px 20px 20px" v-if="orderData.address">
            <div style="font-size: 18px">收件地址</div>
            <div style="font-size: 14px; margin: 16px 0 16px">取货地址</div>
            <div
              style="font-size: 12px; color: #7b7b7c"
              v-if="orderData.address.length != 0"
            >
              （+{{ orderData.address.mobile_prefix }}）{{
                orderData.address.mobile
              }}
            </div>
            <div style="font-size: 12px; color: #7b7b7c; margin-top: 6px">
              {{ orderData.address.country }}{{ orderData.address.province
              }}{{ orderData.address.city }}{{ orderData.address.district
              }}{{ orderData.address.address }}
              <span
                v-if="orderData.state == 1"
                style="margin-left: 10px; color: #3b7cbe; cursor: pointer"
                @click="openAddress"
                >{{ orderData.address.length != 0 ? "编辑" : "管理地址" }}</span
              >
            </div>
          </div>
          <div
            class="wuliu"
            v-if="orderData.state == 4 || orderData.state == 5"
          >
            <div style="font-size: 18px; padding: 10px 0 20px">物流资讯</div>
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :timestamp="activity.timestamp"
                :color="activity.color"
              >
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </div>
          <div
            v-if="orderData.state == 2 || orderData.state == 3"
            style="
              margin: 40px 0 20px;
              padding: 0 10px;
              font-size: 16px;
              color: #b0b0b0;
              border-left: 1px solid #eee;
            "
          >
            没有物流资讯
          </div>
          <div class="caidai">
            <div v-for="item in 10" :key="item" style="display: flex">
              <div class="red"></div>
              <div class="blue"></div>
            </div>
          </div>
        </div>
        <div class="order">
          <div class="order-list">
            <div class="title">
              <div>
                {{ orderData.shop }}
                <el-button
                  @click="toStore"
                  style="margin-left: 10px"
                  size="mini"
                  plain
                  icon="el-icon-burger"
                  >查看商店</el-button
                >
              </div>
              <div>订单编号:{{ orderData.order_no }}</div>
            </div>
            <div class="item" v-for="item in orderData.goods" :key="item.id">
              <div style="margin-right: 10px">
                <el-image
                  style="width: 90px; height: 90px"
                  :src="item.image"
                  fit="fit"
                ></el-image>
              </div>
              <div style="width: 75%">
                <div style="font-size: 14px; padding-bottom: 30px">
                  {{ item.title }}
                  {{ item.difference ? ",[" + item.difference + "]" : "" }}
                </div>
                <div>x{{ item.number }}</div>
              </div>
                            <div

                style="color: #ee4d2d; width: 15%; text-align: right"
              >
                RM{{ item.price }}
              </div>
              <div
                v-if="
                  parseFloat(item.coupon_discount_price) +
                    parseFloat(item.discount_price) +
                    parseFloat(item.suit_discount_price) ==
                  'nihao'
                "
                style="color: #ee4d2d; width: 15%; text-align: right"
              >
                RM{{ item.price }}
              </div>
              <div v-if="1===0" style="color: #ee4d2d; width: 15%; text-align: right">
                <span style="color: #b0b0b0; text-decoration: line-through">
                  RM{{ item.price }}
                </span>
                <!-- RM{{
                  (
                    parseFloat(item.price * item.number) -
                    (parseFloat(item.coupon_discount_price) +
                      parseFloat(item.discount_price) +
                      parseFloat(item.suit_discount_price))
                  ).toFixed(2)
                }} -->
                RM{{ (parseFloat(item.actual_payment) /item.number).toFixed(2)}}
              </div>
            </div>
          </div>
          <div class="order-info" v-if="orderData.pay">
            <div class="art">
              <div class="text1">商品小计</div>
              <div class="text2">RM{{ orderData.pay.order_price }}</div>
            </div>
            <div class="art">
              <div class="text1">运费</div>
              <div class="text2">RM{{ orderData.pay.freight_price }}</div>
            </div>
            <div class="art" v-if="orderData.coupon_price != 0">
              <div class="text1">优惠券折扣</div>
              <div class="text2">RM{{ orderData.coupon_price }}</div>
            </div>
            <div class="art" v-if="orderData.discount_price != 0">
              <div class="text1">商品折扣</div>
              <div class="text2">RM{{ orderData.discount_price }}</div>
            </div>
            <div class="art" v-if="orderData.suit_price != 0">
              <div class="text1">套装优惠</div>
              <div class="text2">RM{{ orderData.suit_price }}</div>
            </div>
            <!-- <div class="art">
              <div class="text1">订单总额</div>
              <div class="text2" style="font-size: 16px">
                RM{{ orderData.pay.price }}
              </div>
            </div> -->
          </div>
          <div class="summary" v-if="orderData.pay">
            <div class="text">
              <div class="text1">订单总额</div>
              <div class="text2" style="font-size: 20px; color: #ee4d2d">
                RM{{ orderData.pay.price }}
              </div>
            </div>
            <div class="text">
              <div class="text1">付款方式</div>
              <div class="text2">
                {{ orderData.pay.pay_type == 0 ? "货到付款" : "虚拟支付" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; text-align: center" v-else>
      <img :src="httpImg" alt="" />
    </div>

    <el-dialog
      title="选择取消原因"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style="margin-top: -20px">
        <el-alert
          title="请选择取消原因。请注意，这将取消订单中的全部商品，并且无法撤销该操作。"
          type="warning"
          :closable="false"
          show-icon
        >
        </el-alert>
        <div>
          <el-radio-group v-model="radio">
            <div
              v-for="(item, i) in cancelOption"
              :key="i"
              style="padding: 14px 16px"
            >
              <el-radio :label="i">{{ item }}</el-radio>
            </div>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">现在不要</el-button>
        <el-button :disabled="radio === ''" type="primary" @click="handleCancel"
          >取消订单</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="发布评价"
      :visible.sync="dialogVisible1"
      width="460px"
      :before-close="handleClose1"
      :close-on-click-modal="false"
    >
      <div style="font-size: 14px">
        <div style="display: flex; align-items: center">
          <div style="margin-right: 20px; min-width: 30px; min-width: 66px">
            <span style="color: #ee4d2d">*</span>星级打分:
          </div>
          <el-rate v-model="comment.star"></el-rate>
        </div>
        <div style="margin: 20px 0">
          <div style="display: flex; align-items: top">
            <div style="margin-right: 20px; min-width: 66px">
              <span style="color: #ee4d2d">*</span>商品评价:
            </div>
            <el-input
              type="textarea"
              style="max-width: 300px"
              v-model="comment.value"
              placeholder="在这里输入您对商品的评论，可以帮助更多想买的人"
              :rows="6"
              show-word-limit
              maxlength="150"
            ></el-input>
          </div>
        </div>
        <div>
          <div style="display: flex; align-items: top">
            <div style="margin-right: 20px; padding-left: 6px; min-width: 66px">
              上传图片:
            </div>
            <UploadFiles3
              :imgLength="6"
              :imageUrl="comment.image"
              :ids="comment.imageID"
            ></UploadFiles3>
          </div>
          <div
            style="margin: 4px 4px 4px 86px; font-size: 12px; color: #b0b0b0"
          >
            图片大小5M以内
          </div>
        </div>
        <div style="margin-top: 20px">
          <div style="display: flex; align-items: top">
            <div style="margin-right: 20px; padding-left: 6px; min-width: 66px">
              是否匿名:
            </div>
            <div>
              <el-radio-group v-model="comment.type">
                <el-radio label="0">匿名</el-radio>
                <el-radio label="1">公开</el-radio>
              </el-radio-group>
              <div style="font-size: 12px; color: #b4b4b4; margin-top: 4px">
                公开后大家可以看到我用户头像和昵称
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button style="min-width: 80px" size="small" @click="handleClose1"
          >取 消</el-button
        >
        <el-button
          style="min-width: 80px"
          size="small"
          type="primary"
          @click="handleComment"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible2" width="800px">
      <div style="margin-top: -30px">
        <buyAddress :show="true"></buyAddress>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="handleXzdz">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getOrderInfo,
  cancelOrder,
  payment,
  confirmOrder,
  commentOrder,
  editOrderAddress,
} from "@/api/buy.js";
import UploadFiles3 from "@/components/UploadFiles3.vue";
import buyAddress from "@/views/buyer/buyAddress.vue";
export default {
  components: {
    UploadFiles3,
    buyAddress,
  },
  data() {
    return {
      seepList: [
        {
          title: "待付款",
          value: 1,
          icon: " el-icon-money",
          show: false,
        },
        {
          title: "待出货",
          icon: "el-icon-truck",
          value: 3,
          show: false,
        },
        {
          title: "待收货",
          icon: "el-icon-box",
          value: 4,
          show: false,
        },
        {
          title: "待评价",
          icon: "el-icon-star-off",
          value: 5,
          show: false,
        },
      ],
      state: [
        "全部",
        "待付款",
        "待出货",
        "待发货",
        "待收货",
        "已完成",
        "已取消",
        "退货/退款",
        "待评论",
      ],
      orderId: "",
      orderData: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      radio: "",
      cancelOption: [
        "需更改收货地址",
        "需要输入/更改优惠券代码",
        "需要修改订单(例如:尺寸，颜色，数量等等)",
        "付款程序太麻烦了",
        "在别处找到更便宜的",
        "不想购买了",
        "其他",
      ],
      ms: "",
      value1: 3,

      comment: {
        value: "",
        star: 0,
        image: [],
        imageID: [],
        type: "0",
      },
      activities: [
        {
          content: "温馨提示：您的订单预计6月29日送达",
          timestamp: "2022-06-28",
          color: "#ee4d2d",
        },
        {
          content:
            "您的订单已经进入京东国际自营广州黄埔保税综合2号仓开始申报，等待清关",
          timestamp: "2022-06-27",
        },
        {
          content: "您的订单预计2022-6-28开始处理，请耐心等待",
          timestamp: "2022-06-26",
        },
      ],
      httpShow: false,
      httpImg: require("../../assets/img/app/http.gif"),
    };
  },
  created() {
    this.orderId = sessionStorage.ORDERID;
    this.orderInfo();
    if (sessionStorage.OPER == "cancel") {
      this.dialogVisible = true;
    }
    if (sessionStorage.OPER == "comment") {
      this.dialogVisible1 = true;
    }
  },
  methods: {
    orderInfo() {
      getOrderInfo({
        id: this.orderId,
      }).then((res) => {
        if (res.code == 1) {
          this.httpShow = true;
          this.orderData = res.data;
          // this.orderData.state = 9;
          // this.orderData.evaluate_state = 1;
          if (this.orderData.state == 2 || this.orderData.state == 3) {
            this.orderData.state = 3;
          }

          this.seepList.forEach((item, index) => {
            if (item.value <= this.orderData.state) {
              this.seepList[index].show = true;
            }
          });
          if (this.orderData.evaluate_state == 0) {
            this.seepList[3].show = false;
          }
          this.ms = this.orderData.create_time;
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    handleCancel() {
      cancelOrder({
        id: sessionStorage.ORDERID,
        reason: this.cancelOption[this.radio],
      }).then((res) => {
        if (res.code == 1) {

          this.$message.success(res.message);
          this.handleClose();
          this.$router.replace("buyListing");
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      sessionStorage.removeItem("OPER");
      this.radio = "";
    },
    handleClose1() {
      this.dialogVisible1 = false;
      sessionStorage.removeItem("OPER");
      this.comment = {
        value: "",
        star: 0,
        image: [],
        imageID: [],
        type: "0",
      };
    },
    handlePay() {
      payment({
        order_id: sessionStorage.ORDERID,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.orderInfo();
        }
      });
    },
    handleConfirm() {
      confirmOrder({
        id: sessionStorage.ORDERID,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("确认收货成功");
          this.orderInfo();
        }
      });
    },
    handleComment() {
      if (this.comment.star == 0) {
        this.$message.info("请为商品打分");
        return false;
      }
      if (this.comment.value == "") {
        this.$message.info("请输入商品评价");
        return false;
      }

      let arr = [];
      this.orderData.goods.forEach((item) => {
        arr.push({
          id: item.id,
          shop_id: this.orderData.shop_id,
          goods_id: item.goods_id,
          star: this.comment.star,
          comment: this.comment.value,
          imgList: this.comment.imageID.join(","),
          is_anonymous: this.comment.type,
          difference: item.difference,
        });
      });
      let obj = {
        order_id: sessionStorage.ORDERID,
        goods_list: arr,
      };
      commentOrder(obj).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.handleClose1();
          this.orderInfo();
        }
      });
    },
    toStore() {
      window.scrollTo(0, 0);
      this.$router.push({
        path: "storeHome",
        query: {
          shop_id: this.orderData.shop_id,
          // addTime: this.infoData.shop.join_time,
          // onlineTime: this.infoData.shop.online_time,
        },
      });
    },
    openAddress() {
      if (this.orderData.address) {
        this.$store.state.addressRadio = this.orderData.address.id;
      }
      this.dialogVisible2 = true;
    },
    handleXzdz() {
      editOrderAddress({
        id: this.orderData.id,
        address_id: this.$store.state.addressRadio,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.message);
          this.orderInfo();
        }
      });
      this.dialogVisible2 = false;
      this.orderData.address = this.$store.state.addressData;
    },
  },
};
</script>
<style lang="less" scoped>
.buyOrderInfo {
  padding: 20px;
  .cell {
    .top {
      background: #fff;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      border-bottom: 1px dashed #eee;
      border-radius: 6px;
    }
    .seep {
      background: #fff;
      border-radius: 6px;
      padding: 30px 20px 30px;
      border-bottom: 1px dashed #eee;
      .text {
        display: flex;
        justify-content: space-between;
        text-align: center;
        padding-top: 20px;
        font-size: 14px;
      }
      .box {
        display: flex;
        align-items: center;
        justify-content: center;

        .round {
          width: 58px;
          height: 58px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 28px;
        }
        .round1 {
          background: #fff;
          border: 4px solid #2cc258;
          color: #2cc258;
        }
        .round2 {
          border-radius: 50%;
          background: #2cc258;
          border: 4px solid #2cc258;
          color: #fff;
        }
        .round3 {
          border-radius: 50%;
          background: #fff;
          border: 4px solid #dbdbdb;
          color: #dbdbdb;
        }
        .line {
          width: 130px;
          height: 4px;
        }
        .line1 {
          background: #dbdbdb;
        }
        .line2 {
          background: #2cc258;
        }
      }
    }
    .type {
      padding: 20px;
      background: #fffcf5;
      border-bottom: 1px dashed #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      font-size: 12px;
      color: #888782;
    }
    .type2 {
      padding: 20px;
      background: #fffcf5;

      display: flex;
      justify-content: right;
      border-radius: 6px 6px 0 0;
    }
    .type3 {
      font-size: 12px;
      padding: 10px 20px;
      line-height: 24px;
      color: #888782;
      background: #fffcf5;
      border-radius: 6px 6px 0 0;
    }
    .address {
      background: #fff;
      //   padding: 20px;
      border-radius: 0 0 6px 6px;
      position: relative;
      display: flex;
      // justify-content: space-between;
      padding-right: 40px;
      .wuliu {
        overflow: scroll;
        padding: 0 10px;
        margin: 40px 10px 20px;
        height: 150px;
        width: 600px;
        overflow-x: hidden;
        border-left: 1px solid #eee;
      }
    }
    .order {
      margin-top: 10px;
      .order-list {
        background: #fff;
        padding: 0 20px 20px;
        border-radius: 6px;
        border-bottom: 1px dashed #eee;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          border-bottom: 1px solid #f0f0f0;
          padding: 14px 0;
        }
        .item {
          padding: 10px 0;
          display: flex;
          align-items: center;
          font-size: 12px;
        }
      }
      .order-info {
        background: #fffefa;
        font-size: 12px;
        border-radius: 6px;
        .art {
          text-align: right;
          display: flex;
          justify-content: right;
          padding: 0 10px;
          border-bottom: 1px dashed #f0f0f0;
          align-items: center;
          .text1 {
            border-right: 1px dashed #f0f0f0;
            padding: 10px 0;
            padding-right: 10px;
            color: #777775;
          }
          .text2 {
            width: 240px;
            padding: 10px 10px;
          }
        }
      }
      .summary {
        margin-top: 10px;
        background: #fafafa;
        .text {
          display: flex;
          justify-content: right;
          align-items: center;
          border-bottom: 1px dashed #f0f0f0;
          font-size: 12px;
          padding: 0 10px;
          text-align: right;
          .text1 {
            border-right: 1px dashed #f0f0f0;
            padding: 10px 10px;
            padding-right: 10px;
            color: #777775;
          }
          .text2 {
            width: 240px;
            padding: 10px 10px;
          }
        }
      }
    }
  }
}
</style>